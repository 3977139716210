<template>
    <div id="history" data-aos="zoom-in">
        <ComponentName text="Historie"></ComponentName>
        <h3>
            Společnost Romtes Service a.s. byla založena v roce 2011 za účelem vyčlenění a provádění odborných prací v
            oboru činnosti - provádění paličských prací, svářečských prací, rovnání a předrovnání kovového materiálu,
            povrchovou úpravu kovů spočívající v apretaci a značení kontislitků a jejich finální úpravu před expedicí,
            zámečnické práce, které převzala po společnosti Romtes, s.r.o. <br><br>

            Současná činnost společnosti je tak pokračováním postupného rozvíjení podnikání, jehož počátek je třeba pro
            věrný obraz hledat v minulosti, kdy na samotném začátku byla činnost firmy Kotlár Rudolf, která prováděla od
            roku 1992 havarijní opravy na horkovodní síti v Třinci a dále od roku 1995 kompletní opravy horkovodních a
            teplovodních sítí v Třinci a Bohumíně. <br><br>

            V rámci dalšího rozvíjení podnikání byla v roce 2000 založena společnost Romtes s.r.o., která tuto činnost
            převzala a nadále prováděla v následujících letech výstavbu dalších etap horkovodních a teplovodních sítí v
            Třinci, a dále rozšířila činnost o pomocné a čistící práce pro společnost Třinecké železárny a.s. <br><br>

            Po roce 2003 nastala větší poptávka prací pro společnost Třinecké železárny a.s., kde došlo k zaměření
            zejména
            na apretační a zámečnické práce, za což je společnost od roku 2009 hodnocena jako jeden z rozhodujících
            dodavatelů prací a služeb. Společnost Romtes s.r.o. i nadále pokračovala v provádění staveb, kdy mimo jiné v
            roce 2006 provedla pro společnost Skanska a.s. bourací a statické zajištění objektů včetně hrubých podlah
            budovy
            Krajského úřadu v Královehradeckém kraji a dále pro tutéž společnost v letech 2009 - 2010 provedla výstavbu
            několika etap kanalizačního řádu stavby Revitalizace povodí Olše v České republice. <br><br>

            V rámci dalšího rozvoje činnosti zejména o specializované práce a činnosti pro společnost Třinecké železárny
            a.s. byla v roce 2011 založena akciová společnost Romtes Service a.s., která převzala od společnosti Romtes,
            s.r.o. veškerou činnost prováděnou pro společnost Třinecké železárny a.s., aby se tak mohla plně soustředit
            na
            kvalitu a rozvoj pouze těchto specializovaných prací a služeb. <br><br>

            Společnost má od roku 2011 zavedený a používá systém managementu jakosti ISO 9001:2016 v oboru Zámečnické
            práce,
            paličské práce, apretace a značení kontislitků, svářečské práce a to po současnost. <br><br>

            Postupným rozvíjením a zkvalitňováním poskytovaných prací a služeb, došlo k zaměření společnosti na
            provádění
            specializovaných prací, zejména finální úpravu kovových materiálu před expedicí, kde jsou kladeny vysoké
            požadavky na kvalitu a preciznost poskytovaných služeb, což se projevilo kladně na hospodářském vývoji
            společnosti, kdy na základě dlouhodobé spokojenosti s kvalitou prováděných prací společnost od roku 2015
            rozšířila poskytované služby v oboru povrchové úpravy kovů, a další činnosti s tímto související, také na
            provozovnu v pronajatých prostorách v areálu TŽ, a.s., v Kladně - Dříň, se zaměřením na finální úpravu
            hutních
            výrobků před expedicí. <br><br>
        </h3>
    </div>
</template>

<style scoped>
h3 {
    font-family: "Scada-Regular";
    font-size: 1rem;
    font-weight: 200;
    color: var(--color-black);
    text-align: justify;
}
</style>

<script>
import ComponentName from './ComponentName.vue';
export default {
    name: 'HistoryComponent',
    components: {
        ComponentName,
    }
};
</script>