<template>
    <footer id="footer" data-aos="zoom-in">
        <div class="footer-container">
            <ComponentName text="Kontakty" style="color: var(--color-primary);"></ComponentName>
            <div class=" contacts-address">
                <div class="contacts">
                    <div class="contact-container">
                        <div class="name-function">
                            <h3>Roman Kotlár</h3>
                            <h4>předseda představenstva</h4>
                        </div>
                        <a href="mailto:roman.kotlar@romtesservice.cz" class="tooltip">
                            <img src="../assets/mail_icon.webp" alt="">
                            <span class="tooltiptext">roman.kotlar@romtesservice.cz</span>
                        </a>
                        <a href="tel:+xxx XXX xxx"></a>
                    </div>
                    <div class="contact-container">
                        <div class="name-function">
                            <h3>Michal Kotlár</h3>
                            <h4>provoz Třinec</h4>
                        </div>
                        <a href="mailto:michal.kotlar@romtesservice.cz" class="tooltip">
                            <img src="../assets/mail_icon.webp" alt="">
                            <span class="tooltiptext">michal.kotlar@romtesservice.cz</span>
                        </a>
                        <a href="tel:+420736750590" class="tooltip">
                            <img src="../assets/phone_icon.webp" alt="">
                            <span class="tooltiptext">+420 736 750 590</span>
                        </a>
                    </div>
                    <div class="contact-container">
                        <div class="name-function">
                            <h3>Zdenek Kotlár</h3>
                            <h4>provoz Kladno</h4>
                        </div>
                        <a href="mailto:zdenek.kotlar@romtesservice.cz" class="tooltip">
                            <img src="../assets/mail_icon.webp" alt="">
                            <span class="tooltiptext">zdenek.kotlar@romtesservice.cz</span>
                        </a>
                        <a href="tel:+420736750585" class="tooltip">
                            <img src="../assets/phone_icon.webp" alt="">
                            <span class="tooltiptext">+420 736 750 585</span>
                        </a>
                    </div>
                    <div class="contact-container">
                        <div class="name-function">
                            <h3>Bronislav Szarzec</h3>
                            <h4>ekonomika a administrativa</h4>
                        </div>
                        <a href="mailto:bruno.szarzec@romtesservice.cz" class="tooltip">
                            <img src="../assets/mail_icon.webp" alt="">
                            <span class="tooltiptext">bruno.szarzec@romtesservice.cz</span>
                        </a>
                        <a href="tel:+420778075647" class="tooltip">
                            <img src="../assets/phone_icon.webp" alt="">
                            <span class="tooltiptext">+420 778 075 647</span>
                        </a>
                    </div>
                    <!-- <div class="contact-container">
                        <div class="name-function">
                            <h3>Martina Kičmerová</h3>
                            <h4>mzdová účetní</h4>
                        </div>
                        <a href="mailto:martina.kicmerova@romtesservice.cz" class="tooltip">
                            <img src="../assets/mail_icon.webp" alt="">
                            <span class="tooltiptext">martina.kicmerova@romtesservice.cz</span>
                        </a>
                        <a href="tel:+420736750598" class="tooltip">
                            <img src="../assets/phone_icon.webp" alt="">
                            <span class="tooltiptext">+420 736 750 598</span>
                        </a>
                    </div> -->
                </div>
                <div class="address">
                    <div class="address-child">
                        <img src="../assets/map_pin.webp" alt="" srcset="">
                        <span class="address-text">
                            Sídlo a kanceláře vedení společnosti <br>
                            Romtes Service, a.s. <br>
                            Václavské náměstí 808/66 <br>
                            110 00 Praha 1 <br>
                        </span>
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1280.1858580000194!2d14.4284952!3d50.079327!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b95f46528e3b5%3A0xc33408dd0a62571!2sRomtes%20Service%20a.s.!5e0!3m2!1scs!2scz!4v1661259677556!5m2!1scs!2scz"
                        style="border:0; width: 100%; height: auto; padding: 0px 2%;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>

            <h3>Společnost používá systém managementu jakosti
                <a href="#" @click.prevent="
                    downloadItem({
                        url:
                            '/download/Certifikat_ISO 9001_2016_CZ.pdf',
                        label: 'Certifikat_ISO 9001_2016_CZ.pdf'
                    })
                ">ISO 9001:2016
                    <img src="../assets/download.webp" alt="" class="download-icon">
                </a>
            </h3>
            <br>
            <a class="by-jvideas" href="https://www.jvideas.cz">
                <img src="../assets/byJVideas.webp" alt="">
            </a>
        </div>
    </footer>

</template>


<style scoped>
footer {
    background-color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-container {
    width: 96%;
    max-width: 1140px;
    padding: 0 2%;
}

.contacts-address {
    display: flex;
    gap: 4rem;
    width: 100%;
}

.contact-container {
    display: flex;
    align-items: center;
    gap: 1vw;
    margin-bottom: 1rem;
    width: 100%;
}

.name-function {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-white);
    text-align: start;
    flex: 4;
}

.name-function h3 {
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 0;
}

.name-function h4 {
    font-size: 1rem;
    line-height: 1;
    margin: 0;
    font-weight: 200;
}

.contact-container a {
    display: flex;
    align-items: center;
    flex: 1
}

.tooltip {
    position: relative;
    display: flex;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    color: #fff;
    background-color: black;
    text-align: center;
    padding: 1rem 1rem;

    /* Position the tooltip */
    position: absolute;
    left: -100%;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.contact-container a img {
    width: 3rem;
}

.contact-container a img:hover {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
}

.contacts-address {
    display: flex;
    flex-direction: column;
}

.address {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.address img {
    width: clamp(4rem, 7.5vw, 6rem);
    margin: 1rem;
}

.address-text {
    display: block;
    color: var(--color-white);
    text-align: center;
    width: 100%;
}

.address-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.address h3 {
    font-size: clamp(.5rem, 1.1vw, 1rem);
}

@media screen and (min-width: 728px) {
    .contacts-address {
        display: flex;
        flex-direction: row;
    }

    .contacts {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .address {
        display: flex;
        flex: 1;
    }
}

.footer-container h3 {
    color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-container h3 a {
    color: var(--color-primary);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 .5rem;
}

.footer-container h3 img {
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.by-jvideas {
    height: 3rem;
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 1rem 0;
}

.by-jvideas img {
    height: 100%;
}
</style>

<script>
import ComponentName from './ComponentName.vue';
import axios from "axios";

export default {
    name: 'FooterComponent',
    components: {
        ComponentName,
    },
    methods: {
        async downloadItem({ url, label }) {
            const response = await axios.get(url, { responseType: "blob" });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);
        },
    },
};
</script>