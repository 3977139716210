<template>
    <div data-aos="zoom-in">
        <ComponentName text="Reference"></ComponentName>
        <div class="carousel" id="refs">
            <vueper-slides :gap="1" :breakpoints="breakpoints"  class="no-shadow" :arrows="true" :visible-slides="4"
                :autoplay="true" :bullets="false" :slide-ratio="1 / 2" :dragging-distance="70">
                <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image" :title="slide.title"
                    :content="slide.content" />
            </vueper-slides>
        </div>
        <div class="references">
            <img src="../assets/tz.webp" alt="">
            <img src="../assets/ms.webp" alt="">
        </div>
        <div class="references">
            <p>Jsme hrdým partnerem <b>&nbsp;Ocelářů</b> &nbsp;&nbsp;<img src="../assets/hco_trinec.webp" alt=""></p>
        </div>
    </div>
</template>

<style scoped>
/* References */
.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}

.vueperslides {
    width: 100%;
}

.references {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: clamp(1rem, 5vw, 10rem);
}

.references p {
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.references p b {
    color: #e0071d;
}

.references img {
    max-height: 50px;
}

</style>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import ComponentName from './ComponentName.vue';

export default {
    components: { VueperSlides, VueperSlide, ComponentName },


    data: () => ({
        slides: [
            { image: require('@/assets/carousel/1.webp') },
            { image: require('@/assets/carousel/2.webp') },
            { image: require('@/assets/carousel/3.webp') },
            { image: require('@/assets/carousel/4.webp') },
            { image: require('@/assets/carousel/5.webp') },
            { image: require('@/assets/carousel/6.webp') },
            { image: require('@/assets/carousel/7.webp') },
            { image: require('@/assets/carousel/8.webp') },
            { image: require('@/assets/carousel/9.webp') },
            { image: require('@/assets/carousel/10.webp') },
            { image: require('@/assets/carousel/11.webp') },
            { image: require('@/assets/carousel/12.webp') },
            { image: require('@/assets/carousel/13.webp') },
            { image: require('@/assets/carousel/14.webp') },
            { image: require('@/assets/carousel/15.webp') },
        ],
        breakpoints: {
            1100: {
                visibleSlides : 4,            },
            900: {
                visibleSlides: 3,
                arrows: false,
            },
            600: {
                visibleSlides: 2,
                arrows: false,
            }
        }
    })
};

</script>