<template>
    <div class="root">
        <img src="../assets/header.webp" class="header-background">
        <div class="header-container">
            <div class="header-text" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <span>Váš <b style="color: var(--color-primary);">partner</b></span><br>
                <span>v hutním průmyslu</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HeaderComponent',
}
</script>
<style scoped>
.root{
    position: relative;
    overflow: hidden;
}
.header-container {
    width: 100%;
    height: 100vh;
    max-width: 1140px;
    left: 0;
    right: 0;
    margin: 0 auto;
    overflow: hidden;
}

.header-background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
    z-index: -1;
    -webkit-filter: grayscale(15%);
    filter: grayscale(15%);
    overflow: hidden;
    background-position: 50% 50%;
    animation: 10s zoom infinite;
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.header-text {
    position: relative;
    font-size: clamp(2.4rem, 10vw, 5rem);
    text-align: start;
    color: var(--color-white);
    top: 45%;
    padding-left: 2%;
}
</style>