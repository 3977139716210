<template>
  <MenuComponent></MenuComponent>
  <HeaderComponent></HeaderComponent>
  <main>
    <ServicesComponent></ServicesComponent>
    <HistoryComponent></HistoryComponent>
    <RefsComponent></RefsComponent>
    <div class="background"></div>
  </main>
  <footer data-aos="zoom-in">
    <FooterComponent></FooterComponent>
  </footer>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
}

:root {
  --color-primary: #D7A31A;
  --color-white: #ffffff;
  --color-black: #000000;
  font-family: "Scada-Regular";
}

@font-face {
  font-family: "Scada-Regular";
  src: local("Scada-Regular"),
    url(./fonts/Scada-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Scada-Bold";
  src: local("Scada-Bold"),
    url(./fonts/Scada-Bold.ttf) format("truetype");
}

html,
body {
  margin: 0px;
  padding: 0px;
}

main {
  width: 96%;
  max-width: 1140px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 2%;
}

a {
  text-decoration: none;
}
</style>

<script>
import ServicesComponent from './components/ServicesComponent.vue'
import HistoryComponent from './components/HistoryComponent.vue'
import RefsComponent from './components/RefsComponent.vue';
import FooterComponent from './components/FooterComponent.vue'
import MenuComponent from './components/MenuComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default {
  name: 'App',
  components: { 
    ServicesComponent,
    FooterComponent, 
    HistoryComponent, 
    RefsComponent, 
    MenuComponent, 
    HeaderComponent 
  }
};
</script>