<template>
    <div class="box-container" id="services" data-aos="zoom-in">
        <ComponentName text="Služby"></ComponentName>
        <div class="box">
            <h2>Apretace</h2>
            <div class="box-content">
                <div class="overlay">
                    <h3>
                        Apretace (opalování brad a hran specializovanou pistolí plamenem) technologických zbytků
                        vzniklých při
                        dělení ocelových sochorů 150 x 150 mm za tepla a za pochodu materiálu, apretace ocelových
                        kruhových
                        kontislitků průměr 320 – 600 mm a kvadrátových kontislitků 350 mm, mechanické odstraňování brad
                        materiálu
                        (obití, opálení).
                    </h3>
                </div>
                <img src="../assets/apretace.webp" alt="" class="box-image">
            </div>
        </div>
        <div class="box">
            <h2>Zámečnické práce</h2>
            <div class="box-content">
                <div class="overlay">
                    <h3>
                        Obrušování hran (odhrotování) tyčové oceli kvadrátového a kruhového průřezu, vybrušování
                        materiálu v
                        místě
                        výskytu vady včetně vizuální kontroly , broušení vad kruhových kontislitků (mechanickým
                        brousícím
                        strojem
                        prům. 320 mm), broušení ploch pro zkoušky materiálu.
                    </h3>
                </div>
                <img src="../assets/zamecnicke_prace.webp" alt="" class="box-image">
            </div>
        </div>
        <div class="box">
            <h2>Paličské práce</h2>
            <div class="box-content">
                <div class="overlay">
                    <h3>
                        Rozpalování kovového šrotu, rozpalování zmetkových sochorů, kontislitků a ostatních
                        technologických
                        celků
                        plamenem.
                    </h3>
                </div>
                <img src="../assets/palicske_prace.webp" alt="" class="box-image">
            </div>
        </div>
        <div class="box">
            <h2>Svářečské práce</h2>
            <div class="box-content">
                <img src="../assets/svarecke_prace.webp" alt="" class="box-image">
                <div class="overlay">
                    <h3>
                        Opravy ocelových struskových pánví pro převoz lité oceli specializovaným technologickým postupem
                        spočívajícím ve vyřezávání prasklin a vad v místě výskytu, následné vyvařování plamenem s
                        doplněním
                        přídavným materiálem a svařování poškozených míst s následnou RTG kontrolou místa opravy.
                    </h3>
                </div>
            </div>
        </div>
        <div class="box">
            <h2>Ostatní práce</h2>
            <div class="box-content">
                <div class="overlay">
                    <h3>
                        Rovnání kulatin a sochorů na hydraulickém lisu KI600, DEL CDC 12,5 m a kulisovém lisu VBM100.
                        <br>
                        Příprava matriálu k expedici, značení kontislitků, označování a příprava vsázkového materiálu k
                        transportu,
                        organizace uskladňování.
                    </h3>
                </div>
                <img src="../assets/ostatni_prace.webp" alt="" class="box-image">
            </div>
        </div>
    </div>
</template>
<script>
import ComponentName from './ComponentName.vue'
export default {
    name: 'ServicesComponent',
    components: {
        ComponentName,
    }
}
</script>
<style scoped>
.box-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2rem;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
    /* height: clamp(325px, 25.5vw, 380px); */
    background: var(--color-primary);
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

article .box h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-black);
    font-size: clamp(1.25rem, 2.5vw, 1.75rem);
    font-family: "Scada-Regular";
    font-weight: 300;
    height: 15%;
    margin: 0;
}

.box-container .box h2 {
    padding: 0;
}

.box-content h3 {
    color: var(--color-black);
    font-size: 1rem;
    font-weight: 200;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-content {
    position: relative;
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-content:hover .overlay {
    opacity: 1;
}

.box-image {
    max-width: 340px;
    max-height: 250px;
}


.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    background-color: var(--color-primary);
}
</style>