<template>
    <article class="root" data-aos="fade-down">
        <nav>
            <img src="../assets/romtes_logo_allwhite.webp" alt="">
            <ul>
                <li v-scroll-to="'#services'">Služby</li>
                <li v-scroll-to="'#history'">Historie</li>
                <li v-scroll-to="'#refs'">Reference</li>
                <li v-scroll-to="'#footer'">Kontakty</li>
            </ul>
        </nav>
    </article>
</template>

<script>
export default {
    name: "MenuComponent",
}
</script>

<style scoped>
.root {
    width: 100vw;
    height: 4rem;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    z-index: 10;
    background-color: rgba(0
    , 0
    , 0
    , .65);
    border-bottom: 1px white solid;
}

nav {
    width: 98%;
    height: 100%;
    max-width: 1140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

nav ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

nav ul li {
    text-decoration: none;
    cursor: pointer;
    color: var(--color-white);
}

img {
    display: none;
}

@media screen and (min-width: 620px) {
    img {
        display: block;
        height: 100%;
        margin-right: auto;
        position: relative;
    }

    nav {
        justify-content: end;
    }

    nav ul {
        gap: 2rem;
    }

    nav ul li {
        font-size: 1.25rem;
    }
}
</style>