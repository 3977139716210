<template>
    <div>
        <h2>{{ text }}</h2>
        <img src="../assets/romtes_logo_h2.webp" alt="">
    </div>
</template>
<script>
export default {
    name: 'ComponentName',
    props: {
        text: String
    }
};
</script>
<style scoped>
div {
    margin: 3rem 0 1rem 0;
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

h2 {
    font-family: "Scada-bold";
    font-size: 2rem;
    z-index: 1;
}

img {
    position: absolute;
    z-index: 0;
    left: -2vw;
    width: clamp(1rem, 16vw, 7rem);
    filter: blur(2px);
}
</style>